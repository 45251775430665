// @ts-check

import DirectoryCache from "offline/DirectoryCache";
import { isPublicUser } from "./Helper";
import { get_user_directory } from "api/zero-api";
import useOrganizationId from "hooks/useOrganizationId";
import { useCurrentUser } from "hooks/reduxHooks";
import { useEffect, useState } from "react";

const DIRECTORY_TTL = 300_000; // 5 minutes
const directoryCacheMap = new Map();

/**
 * @param {string} orgId 
 * @returns {Promise<DirectoryUser[]>}
 */
async function fetchOfflineDirectory(orgId) {
    try {
        const cache = new DirectoryCache(orgId);
        return await cache.getAll();
    } catch (err) {
        console.error('Could not retrieve offline directory:', err);
        return [];
    }
}

function getMapKey(orgId, user) {
    return `${orgId}:${user.uuid ?? user.user_uuid}`;
}

async function fetchDirectory(orgId, user) {
    try {
        let directory;

        if (isPublicUser(user)) {
            directory = await fetchOfflineDirectory(orgId);
        } else {
            const res = await get_user_directory('?for_directory_cache=1');
            directory = await res.json();
        }

        directoryCacheMap.set(getMapKey(orgId, user), {
            timestamp: Date.now(),
            data: directory
        });

        return directory;
    } catch (err) {
        return [];
    }
}

/**
 * @param {string} orgId 
 * @param {any} user 
 * @returns {Promise<DirectoryUser[]>}
 */
async function loadDirectoryFromCache(orgId, user) {
    const mapKey = getMapKey(orgId, user);
    const directoryCache = directoryCacheMap.get(mapKey);

    if (directoryCache && (Date.now() - directoryCache.timestamp < DIRECTORY_TTL)) {
        return directoryCache.data;
    } else if (directoryCache && directoryCache.fetchInProgress) {
        return await directoryCache.fetchInProgress;
    } else {
        const fetchInProgress = new Promise((resolve) => {
            fetchDirectory(orgId, user).then(resolve);
        });

        directoryCacheMap.set(mapKey, {fetchInProgress});

        const directory = await fetchInProgress;
        return directory;
    }
}

/**
 * 
 * @param {(users: DirectoryUser[]) => DirectoryUser[]} [userFilter] 
 * @returns {DirectoryUser[]}
 */
export function useCachedDirectory(userFilter) {
    const orgId = useOrganizationId();
    const user = useCurrentUser();

    /** @type {useState<DirectoryUser[]>} */
    const [directory, setDirectory] = useState([]);

    useEffect(() => {
        loadDirectoryFromCache(orgId, user)
        .then((newDirectory) => {
            setDirectory(userFilter ? userFilter(newDirectory) : newDirectory);
        })
        .catch(() => setDirectory([]));
    }, [orgId, user, userFilter]);

    return directory;
}