// @ts-check

import { tasksApi } from "api/zero-api";
import ConfirmModal from "components/Shared/ConfirmModal";
import { getErrorMessageFromResponse } from "other/Helper";
import NotificationAlert from "other/NotificationAlert";

/**
 * @param {{
*  task: Task,
*  onClose: () => void,
*  onMutation?: (task: Task) => void,
*  onOfflineMutation?: (task: Task) => void,
* }} props 
*/
export default function DeleteTaskConfirmationModal({task, onClose, onMutation, onOfflineMutation}) {
    const onDelete = async () => {
        try {
            if (task.linked_submission?.submission_uuid?.startsWith('offline:')) {
                onOfflineMutation?.(task);
            } else {
                await tasksApi.delete(task.uuid);
                onMutation?.(task);
            }
            onClose();
        } catch (err) {
            NotificationAlert('error', '', 'Could not delete todo.');
            const message = await getErrorMessageFromResponse(err, 'Unknown error while deleting task');
            console.error(message);
        }
    }

    return (
        <ConfirmModal
            show
            cancel={onClose}
            confirm={onDelete}
            title={"Confirmation"}
            body={"Are you sure you want to delete this todo?"}
            confirmButtonName={"Delete"}
        />
    );
}