// @ts-check

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { useState } from "react";
import DeleteTaskConfirmationModal from "./DeleteTaskModal";
import TaskAssignmentAvatarList from "./TaskAssignmentAvatarList";
import TaskDetailModal from "./TaskDetailModal";

/**
 * @param {{
 *  task: Task
 *  onOfflineUpdate?: (taskBody: TaskUpsertBody, taskId?: string) => Promise<Task>;
 *  onOfflineDelete?: (task: Task) => void;
 *  submissionId?: string,
 *  fieldId?: string
 * }} props
 */
export default function TaskDetailPanel({task: initialTask, onOfflineUpdate, onOfflineDelete, submissionId, fieldId}) {
    const [task, setTask] = useState(initialTask);
    /** @type {useState<"detail" | "delete" | null>} */
    const [activeModal, setActiveModal] = useState(null);

    const closeModal = () => setActiveModal(null);

    const updateTask = (updatedTask) => {
        setTask(updatedTask);
    }

    const updateOfflineTask = async (taskBody, taskId) => {
        if (onOfflineUpdate) {
            const updatedTask = await onOfflineUpdate(taskBody, taskId);
            setTask(updatedTask);
        }
    }

    const deleteOfflineTask = (task) => {
        if (onOfflineDelete) {
            onOfflineDelete(task);
            setTask(null);
        }
    }

    if (!task) {
        return null;
    }

    return (
        <>
            { activeModal === "detail" &&
                <TaskDetailModal task={task} onClose={closeModal} onMutation={updateTask} onOfflineMutation={updateOfflineTask} submissionId={submissionId} fieldId={fieldId} />
            }
            { activeModal === "delete" &&
                <DeleteTaskConfirmationModal task={task} onClose={closeModal} onMutation={() => setTask(null)} onOfflineMutation={deleteOfflineTask} />
            }
            <div className="flex panel panel-border pad-15" style={{justifyContent: 'space-between', borderRadius: '8px', margin: 0}}>
                <div className="flex zero-dark-grey" style={{alignItems: "center"}}>
                    <Tag className="zero-dark-grey">To-Do</Tag>
                    <div>{task.title}</div>
                </div>
                <div className="flex gap-lg" style={{alignItems: 'center'}}>
                    <TaskAssignmentAvatarList
                        task={task}
                        onMutation={updateTask}
                        onOfflineMutation={updateOfflineTask}
                    />
                    <div className="flex gap-sm" style={{fontSize: 14}}>
                        <EditOutlined onClick={() => setActiveModal('detail')} />
                        <span className="zero-grey">&#124;</span>
                        <DeleteOutlined className="zero-delete-red" onClick={() => setActiveModal('delete')} />
                    </div>
                </div>
            </div>
        </>
    );
}