export const CheckInUserType = {
    SELF: 'self',
    USER: 'user',
    GUEST: 'guest',
}

export const CheckInStatus = {
    NONE: 'none',
    PENDING: 'pending',
    COMPLETED: 'completed',
    FAILED: 'failed',
}

export const CheckInLocale = {
    OFFICE: 'office',
    REMOTE: 'remote',
    OTHER: 'other',
}

export const DeskType = {
    OFFICE: 'office',
    CUBICLE: 'cubicle',
    WORKSTATION: 'workstation',
    BENCH: 'bench',
    CONFERENCE_ROOM: 'conference_room',
    MEETING_ROOM: 'meeting_room',
    RECEPTION: 'reception',
    OTHER: 'other',
}

export const PrettyDeskTypeMap = {
    [DeskType.OFFICE]: 'Office',
    [DeskType.CUBICLE]: 'Cubicle',
    [DeskType.WORKSTATION]: 'Workstation',
    [DeskType.BENCH]: 'Bench',
    [DeskType.CONFERENCE_ROOM]: 'Conference Room',
    [DeskType.MEETING_ROOM]: 'Meeting Room',
    [DeskType.RECEPTION]: 'Reception',
    [DeskType.OTHER]: 'Other',
}

export const CheckInAlertRepeatPeriod = {
    DAILY: 'daily',
    WEEKDAY: 'weekday',
    WEEKEND: 'weekend'
}

export const AllowedFeatures = {
    DESKS: 'desks',
    VOICE_CALLS: 'voice_calls',
    PUBLIC_FORMS: 'public_forms',
}

export const IncidentStatus = {
    DRAFT: 'draft',
    OPEN: 'open',
    IN_REVIEW: 'in_review',
    CLOSED: 'closed'
}

export const PrettyIncidentStatusMap = {
    [IncidentStatus.DRAFT]: 'Draft',
    [IncidentStatus.OPEN]: 'Open',
    [IncidentStatus.IN_REVIEW]: 'In Review',
    [IncidentStatus.CLOSED]: 'Closed',
}

export const IncidentCommentType = {
    INCIDENT_SUBMITTED: 'incident_submitted',
    STATUS_CHANGE: 'status_change',
    INCIDENT_MOVED: 'incident_moved',
    USER_COMMENT: 'user_comment',
    INCIDENT_EDITED: 'incident_edited',
    SUBMISSION_CREATED: 'submission_created',
    SUBMISSION_DELETED: 'submission_deleted',
    SUBMISSION_EDITED: 'submission_edited',
}

export const orderedIncidentFields = [
    'event_location',
    'incident_type',
    'event_type_uuid',
    'custom_field_1',
    'date_of_injury',
    'time_began_work',
    'event_time',
    'event_time_determined',
    'full_name',
    'job_title',
    'street_address',
    'city',
    'state',
    'zip',
    'date_of_birth',
    'date_hired',
    'sex',
    'where_occurred',
    'before_incident',
    'what_happened',
    'what_body_part',
    'what_object',
    '$injury_or_illness',
    'incident_cause_uuid',
    'case_classification',
    'date_of_death',
    'away_from_job_days',
    'on_restriction_days',
    'body_front_parts',
    'body_back_parts',
    'health_care_name',
    'health_care_facility',
    'health_care_street',
    'health_care_city',
    'health_care_state',
    'health_care_zip',
    'emergency_room_used',
    'hospitalized',
    'attachments',
    'corrective_actions',
];

export const PrettyIncidentFieldNameMap = {
    'event_location': 'Location Address',
    'incident_type': 'Case Type',
    'event_type_uuid': 'Event Type',
    'date_of_injury': 'Date of Event',
    'time_began_work': 'Time employee began work',
    'event_time': 'Time of event',
    'event_time_determined': 'Time of event determined',
    'full_name': 'Full Name',
    'job_title': 'Job Title',
    'street_address': 'Street',
    'city': 'City',
    'state': 'State',
    'zip': 'ZIP',
    'date_of_birth': 'Date of Birth',
    'date_hired': 'Date Hired',
    'sex': 'Gender',
    'where_occurred': 'Where did the event occur?',
    'before_incident': 'What was the employee doing just before the incident occurred?',
    'what_happened': 'What Happened?',
    'what_body_part': 'What was the injury or illness?',
    'what_object': 'What object or substance directly harmed the employee?',
    '$injury_or_illness': 'Incident Type',
    'incident_cause_uuid': 'Incident Cause',
    'case_classification': 'Case Classification',
    'date_of_death': 'Date of Death',
    'away_from_job_days': 'Days Away from Work',
    'on_restriction_days': 'Days Restricted',
    'body_front_parts': 'Body Chart (Front)',
    'body_back_parts': 'Body Chart (Back)',
    'health_care_name': 'Name of physician',
    'health_care_facility': 'Healthcare Facility',
    'health_care_street': 'Healthcare Street',
    'health_care_city': 'Healthcare City',
    'health_care_state': 'Healthcare State',
    'health_care_zip': 'Healthcare ZIP',
    'emergency_room_used': 'Emergency Room',
    'hospitalized': 'Hospitalized Overnight',
    'attachments': 'Attachments',
    'corrective_actions': 'Corrective Actions',
};

const WITH_FREE_DATA = [
    'TextInput', 'NumberInput', 'TextArea', 'DatePicker', 'TimePicker', 'DynamicListInput', 'UserDropdown'
];

const WITH_OPTIONS = ['Dropdown', 'Tags', 'Checkboxes', 'RadioButtons'];

export const FORM_ELEMENTS = {
    WITH_FREE_DATA,
    WITH_OPTIONS,
    FOR_INPUT: [...WITH_FREE_DATA, ...WITH_OPTIONS, 'Signature', 'Attachments'],
    forTableView: [...WITH_FREE_DATA, ...WITH_OPTIONS, 'Attachments'],
    centeredElements: ["Attachments", "NumberInput"],
    filterable: ['TextInput', 'NumberInput', 'TextArea', 'DynamicListInput', ...WITH_OPTIONS],
}

export const CUSTOM_FIELD_TYPES = {
    INCIDENT_CUSTOM_FIELD_1: 'incident_custom_field_1',
    INCIDENT_CUSTOM_FIELD_2: 'incident_custom_field_2',
    INCIDENT_CUSTOM_FIELD_3: 'incident_custom_field_3',
    INCIDENT_CUSTOM_FIELD_4: 'incident_custom_field_4'
}

export const FormType = {
    REGULAR: 0,
    //AUDIT: 1, // Unused
    INCIDENT: 2,
    LMS: 3,
    POST: 4,

    allowsCustomConfirmationPage(type) {
        return [FormType.REGULAR, FormType.LMS].includes(type);
    },

    allowedOffline(type) {
        return [FormType.REGULAR, FormType.POST].includes(type);
    },

    hasTeamAssignments(type) {
        return [FormType.REGULAR, FormType.LMS].includes(type);
    },

    canBeShared(type) {
        return [FormType.REGULAR, FormType.LMS].includes(type);
    },

    /** When true, "Create post" action will be available for field comments and attachments. */
    canCreatePost(type) {
        return [FormType.REGULAR, FormType.INCIDENT, FormType.LMS].includes(type);
    },

    mapToName(type) {
        let formTypeName = 'Form';
        if (type === FormType.LMS) {
            formTypeName = 'Course';
        } else if (type === FormType.INCIDENT) {
            formTypeName = 'Incident';
        } else if (type === FormType.POST) {
            formTypeName = 'Post';
        }
        return formTypeName;
    }
};

export const UserRole = {
    ADMIN: 'admin',
    TEAM_LEAD: 'team_lead',
    MEMBER: 'user',
    CONTRIBUTOR: 'user_restricted',
    VIEWER: 'viewer',
    PUBLIC: 'public',
    INVALID: '--invalid--'
};

export const GLOBAL_ATTACHMENT_LIMIT = 25;