// @ts-check

import { tasksApi } from "api/zero-api";
import AvatarAssignmentList from "components/Shared/AvatarAssignmentList";
import { getErrorMessageFromResponse } from "other/Helper";
import NotificationAlert from "other/NotificationAlert";

/**
 * @param {{
 *  task: Task;
 *  onMutation?: (task: Task) => void;
 *  onOfflineMutation?: (taskBody: TaskUpsertBody, taskId: string) => void;
 * }} props
 */
export default function TaskAssignmentAvatarList({task, onMutation, onOfflineMutation}) {
    const assignUsers = async (userIds) => {
        try {
            if (task.linked_submission?.submission_uuid.startsWith('offline:')) {
                const body = {
                    assigned_user_uuids: userIds,
                    title: task.title,
                    status: task.status,
                    due_date: task.due_date,
                    submission_uuid: task.linked_submission.submission_uuid,
                    submission_field_uuid: task.linked_submission.field_uuid,
                };
                onOfflineMutation(body, task.uuid);
            } else {
                const res = await tasksApi.partialUpdate(task.uuid, {assigned_user_uuids: userIds});
                const updatedTask = await res.json();
                onMutation?.(updatedTask);
            }
        } catch (err) {
            NotificationAlert('error', '', `Could not change assigned users.`);
            const message = await getErrorMessageFromResponse(err, 'Unknown error while assigning users to task.');
            console.error(message);
        }
    }

    const unassignUser = async (userToRemove) => {
        assignUsers(
            task
            .assigned_users
            .filter(user => user !== userToRemove)
            .map(user => user.uuid)
        );
    }

    return (
        <AvatarAssignmentList
            assignedUsers={task.assigned_users ?? []}
            showMassSelectOptions={false}
            onChange={assignUsers}
            userFilter={(users) => users.filter(user => user.role !== 'viewer')}
            showRemoveAction
            removeActionText="Unassign User"
            onRemoveUser={unassignUser}
        />
    )
}