// @ts-check
import { createContext, useCallback, useContext, useEffect, useState } from "react";

const TaskContext = createContext(null);

/** @returns {ReturnType<useContextValue>} */
export function useTaskContext() {
    return useContext(TaskContext);
}

export function TaskContextProvider({initialTasks = [], children}) {
    const value = useContextValue(initialTasks);

    return (
        <TaskContext.Provider value={value}>
            {children}
        </TaskContext.Provider>
    )
}

function useContextValue(initialTasks) {
    /** @type {useState<Task[]>} */
    const [tasks, setTasks] = useState(initialTasks ?? []);

    useEffect(() => {
        setTasks(initialTasks ?? []);
    }, [initialTasks]);

    const upsertTask = useCallback((/**@type {Task}*/task) => {
        const index = tasks.findIndex(t => t.uuid === task.uuid);
        if (index === -1) {
            setTasks((tasks) => [task, ...tasks]);
        } else {
            setTasks((tasks) => {
                const newTasks = [...tasks];
                newTasks[index] = task;
                return newTasks;
            });
        }
    }, [tasks]);

    const removeTask = useCallback((/**@type {Task}*/task) => {
        const index = tasks.findIndex(t => t.uuid === task.uuid);
        if (index === -1) {
            return;
        }
        setTasks((tasks) => {
            const newTasks = [...tasks];
            newTasks.splice(index, 1);
            return newTasks;
        });
    }, [tasks]);

    return {
        tasks,
        setTasks,
        upsertTask,
        removeTask,
    }
}

