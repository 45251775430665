// @ts-check

import {useCallback, useEffect, useState} from 'react';
import {Select} from 'antd';
import {get_user_directory} from 'api/zero-api';
import {isPublicUser, safeProfilePic} from 'other/Helper';
import DirectoryCache from 'offline/DirectoryCache';
import useZeroSelector from 'hooks/useZeroSelector';
import { useCachedDirectory } from 'other/directoryUtils';

const USER_LIST_LIMIT = 50;
const SELECTED_USER_LIMIT = 50;

function filterDirectory(directory, filter) {
    let filteredDirectory = [...directory];

    if (filter) {
        const lowerFilter = filter.toLowerCase();

        filteredDirectory = filteredDirectory.filter(user => {
            let {first_name, last_name, email} = user;
            first_name = first_name.toLowerCase();
            last_name = last_name.toLowerCase();
            email = email.toLowerCase();
            const fullName = `${first_name} ${last_name}`;

            if (fullName.toLowerCase().includes(lowerFilter)) return true;
            if (email.includes(lowerFilter)) return true;

            return false;
        })
    }

    return filteredDirectory;
}

function convertUserToOption(user) {
    const name = `${user.first_name} ${user.last_name}`;
    const emailOrTitle = user.email || user.title || '';

    return {
        value: user.uuid,
        label: (
            <span>
                {safeProfilePic(user, "img-circle dropdown", "dropdown", {display: "inline-block"})}
                {name} {emailOrTitle && <>&bull; <span style={{fontSize: '85%'}}>{emailOrTitle}</span></>}
            </span>
        )
    };
}

function generateOptions(directory, filter) {
    let filteredDirectory = filterDirectory(directory, filter);
    const options = filteredDirectory.slice(0, USER_LIST_LIMIT).map(convertUserToOption);

    if (directory.length > USER_LIST_LIMIT) {
        options.push({
            // @ts-ignore
            label: 'Additional users are hidden. Change filter to see them.',
            value: '__this_should_never_be_an_option__d9407dab-e0f7-42c3-bb40-1a99ab93b3b5',
            disabled: true
        })
    }

    return options;
}


/**
 * @param {{
 *  selectedUsers: DirectoryUser[],
 *  onChange: (users: DirectoryUser[]) => void,
 *  disabled?: boolean,
 *  userFilter?: (users: DirectoryUser[]) => DirectoryUser[]
 * }} props 
 */
export default function MultiUserSelect({selectedUsers, onChange, disabled, userFilter}) {
    const [filter, setFilter] = useState('');
    const [options, setOptions] = useState([]);
    const [userMap, setUserMap] = useState({});
    const directory = useCachedDirectory(userFilter);

    // update options when filter changes
    useEffect(() => {
        setOptions(generateOptions(directory, filter));
    }, [filter, directory]);

    // build user map from directory
    useEffect(() => {
        if (directory.length > 0) {
            const mapping = {};
            directory.forEach(user => {
                const {uuid, first_name, last_name, email, title, avatar_data} = user;
                mapping[uuid] = {
                    uuid,
                    first_name,
                    last_name,
                    email,
                    title,
                    avatar_data
                };
            });
            setUserMap(mapping);
        } else {
            setUserMap({});
        }
    }, [directory]);

    const usersChangedCallback = useCallback((values) => {
        if (values.length <= SELECTED_USER_LIMIT) {
            onChange(values.map(({value}) => userMap[value]))
        }
    }, [userMap, onChange]);

    return (
        <div>
            <Select
                mode="multiple"
                style={{width: '100%'}}
                placeholder="Select or search..."
                options={options}
                loading={!disabled && directory.length === 0}
                disabled={disabled}
                virtual={false}
                labelInValue={true}
                dropdownStyle={{zIndex: '1200'}}
                onChange={usersChangedCallback}
                value={selectedUsers.map(convertUserToOption)}
                showSearch={true}
                filterOption={false}
                onSearch={setFilter}
                onDropdownVisibleChange={visible => {
                    !visible && setFilter('')
                }}
                allowClear={true}
            />
        </div>
    );
}
