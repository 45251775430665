import {
    get_submission,
    update_submission,
    get_assignment,
    delete_submission,
    create_submission,
    my_form_assignments,
    get_form,
} from 'api/zero-api';
import { syncForm } from 'offline/FormsCache';
import { syncLocalDraft } from 'offline/SubmissionDraftsCache';
import { fileUpload, generateUUID, getReduxState, isPublicUser, objectIsEmpty } from 'other/Helper';

import safe_get from 'other/SafeGet.js';
import BaseService from './baseServices';
import { FormType } from 'other/Constants';
import { DebugLogger } from 'other/DebugLogger';

const debugLogger = new DebugLogger("Form Service");

/** @type {FormsService} */
let singleton;
export default class FormsService extends BaseService {
    /**
     * @param {boolean} isOffline 
     * @param {OfflineDataCachesContext} caches 
     */
    static getFormsService(isOffline, caches) {
        if (!singleton) {
            singleton = new FormsService();
        }
        singleton.init(isOffline, caches);
        return singleton;
    }

    /**
     * @param {boolean} isOffline 
     * @param {OfflineDataCachesContext} caches 
     */
    init(isOffline, caches) {
        this.isOffline = isOffline;
        this.caches = caches;
        this.drafts = caches.submissionDrafts.cache;
        this.assignmentCache = caches.formAssignmentsCache;
        this.initialData = caches.initialData;
    }

    async syncLocalDraft(submissionId) {
        if (!this.isOffline) {
            const draft = await this.drafts.get(submissionId);
            if (draft._id) {
                return await syncLocalDraft(this.drafts, draft);
            }
        }
        return null;
    }

    shouldCacheSubmission(submission) {
        return submission.draft && submission.form.form_type === 0;
    }

    async cacheDraft(submissionId, remoteSubmission, localSubmission) {
        if (!this.shouldCacheSubmission(remoteSubmission)) {
            // this sumbmission should not be cached
            return remoteSubmission;
        } else if (localSubmission._id) {
            // already have a cached version
            const newDoc = this.drafts.createUpdatedDocument(localSubmission, remoteSubmission, Date.now() / 1000);
            await this.drafts.set(submissionId, newDoc);
            await this.drafts.processAttachments(submissionId);
            return await this.drafts.get(submissionId);
        } else {
            // don't have a cached version
            await this.drafts.syncDraft(submissionId, remoteSubmission);
            return remoteSubmission;
        }
    }

    /**
     * @param {string} submissionId 
     * @returns {object}
     */
    async getSubmission(submissionId) {
        const localSubmission = await this.drafts.get(submissionId);
        if (this.isOffline || submissionId.startsWith("offline:")) {
            return localSubmission;
        } else {
            const res = await get_submission(submissionId)
            const data = await res.json();
            const remoteSubmission = safe_get(data, "submission", {});

            return await this.cacheDraft(remoteSubmission.submission_uuid, remoteSubmission, localSubmission);
        }
    }

    /**
     * @param {string} formId 
     * @param {string} teamId 
     * @returns {Promise<object>}
     */
    async createOnlineDraft(formId, teamId) {
        const body = {
            form_uuid: formId,
            team_uuid: teamId
        };
        const response = await create_submission(JSON.stringify(body));
        const data = await response.json();
        return safe_get(data, "submission", "");
    }

    /**
     * @param {string} formId 
     * @param {string} teamId 
     * @param {number} formType
     * @param {string} [assignmentId]
     * @returns {Promise<string>}
     */
    async createDraft(formId, teamId, formType = FormType.REGULAR, assignmentId = null) {
        if (!FormType.allowedOffline(formType)) {
            return (await this.createOnlineDraft(formId, teamId)).submission_uuid;
        }

        let form = this.caches.forms.find(form => form.form_uuid === formId);
        const team = this.initialData.teams.find(team => team.uuid === teamId);

        // if form is not cached locally and we are online, fetch the form and chache it.
        if (!form && !this.isOffline) {
            form = await this.syncForm(formId);

            if (!form) {
                // could not fetch form from API
                throw new Error('Could not create new offline submission: Invalid form')
            }
        }

        // if team is not cached locally and we are online, fetch initial data and cache it.

        if (!team || !form) {
            throw new Error('Could not create new offline submission: Invalid team or form');
        }

        const localDraftId = await this.drafts.createDraft(form, team, this.initialData.user, assignmentId);
        return localDraftId;
    }

    async updateDraft(submissionId, body, formType, options = {}) {
        const dlog = debugLogger.branch('updateDraft');
        dlog("submissionId", submissionId);
        const docExistsLocally = await this.drafts.exists(submissionId);
        dlog("docExistsLocally", docExistsLocally);
        if (!FormType.allowedOffline(formType) || !docExistsLocally) {
            // not a regular submission draft, only update remote
            const response = await update_submission(submissionId, JSON.stringify(body));
            const data = await response.json();
            return data.submission;
        }

        const updatedProperties = {
            fields: body.fields,
            assignment_uuid: body.assignment_uuid ?? null,
        };

        if (body.commit) {
            updatedProperties.$submitted = true;
        }

        dlog("calling update draft on drafts cache with:", updatedProperties);
        const updatedDraft = await this.drafts.updateDraft(submissionId, updatedProperties);

        dlog("updatedDraft", updatedDraft);

        if (updatedProperties.assignment_uuid) {
            await this.assignmentCache.linkSubmission(updatedProperties.assignment_uuid, submissionId, body.commit === true);
        }

        if (options?.syncNow) {
            dlog("syncing local draft");
            const error = await this.syncLocalDraft(submissionId);
            if (error) {
                throw error;
            }
        }

        return updatedDraft;
    }

    /**
     * @param {string} submissionId
     * @param {string} assignmentId
     * @param {number} formType
     */
    async deleteDraft(submissionId, assignmentId, formType) {
        if (!FormType.allowedOffline(formType)) {
            await delete_submission(submissionId, {isDraft: true});
            return;
        }

        await this.drafts.delete(submissionId, {includeAttachments: true});
        if (assignmentId) {
            await this.assignmentCache.unlinkSubmission(assignmentId, submissionId);
        }
        
        const error = await this.syncLocalDraft(submissionId);

        if (error) {
            throw error;
        }
    }

    /**
     * @param {string} submissionId
     * @param {string} assignmentId
     * @param {number} formType
     */
    async deleteSubmission(submissionId, assignemntId, formType) {
        if (!FormType.allowedOffline(formType)) {
            await delete_submission(submissionId);
            return;
        }

        const draft = await this.drafts.get(submissionId);
        if (!draft._id) {
            await delete_submission(submissionId);
        } else {
            await this.deleteDraft(submissionId, assignemntId, formType);
        }
    }

    /**
     * @param {string} assignmentId 
     */
    async getAssignment(assignmentId) {
        if (this.isOffline) {
            return this.assignmentCache.get(assignmentId);
        } else {
            const response = await get_assignment(assignmentId);
            const content = await response.json();
            const assignment = safe_get(content, 'assignment', {});
            return assignment;
        }
    }

    async getAssignments(query="") {
        if (this.isOffline) {
            return this.caches.formAssignments;
        } else {
            const response = await my_form_assignments(query);
            const content = await response.json();
            const assignments = safe_get(content, 'assignments', []);
            return assignments;
        }
    }

    async fileUploadForSubmission(submissionId, params, file, progressCallback, successCallback, errorCallback, offlineSaveCallback) {
        const dlog = debugLogger.branch('fileUploadForSubmission');

        const user = getReduxState().user.user;
        if (isPublicUser(user)) {
            const attachmentId = generateUUID();
            const url = URL.createObjectURL(file.file);
            const attachment = {
                attachment_uuid: attachmentId,
                file_path: url,
                file_name: file.file.name,
                public_url: url,
                mime_type: file.file.type,
                file: file.file,
            }

            return offlineSaveCallback(attachment);
        }


        dlog("submissionId", submissionId);
        dlog("params", params);
        if (submissionId && await this.drafts.exists(submissionId)) {
            dlog("saving offline attachment");
            try {
                const attachment = await this.drafts.saveOfflineAttachment(submissionId, file.file);
                offlineSaveCallback(attachment);
            } catch (err) {
                errorCallback(err);
            }
        } else {
            dlog("uploading attachment");
            fileUpload(null, params, file, progressCallback, successCallback, errorCallback);
        }
    }

    /** 
     * Deletes local cache version of attachment if it exists. No-op if submission/attachment does not exist.
     * Does not alter submission data, this is for resource clean-up only.
     * Attachment should be removed from submission data or you'll have issues.
     */
    async deleteLocalSubmissionAttachment(submissionId, attachmentId) {
        try {
            await this.drafts.deleteAttachment(submissionId, attachmentId);
        } catch (_err) {
            // attachment or submission does not exist in local cache
            // ignore error (logged to console already)
        }
    }

    /**
     * Short-hand to call syncForm from FormsCache module. Gets form from API or formData parameter, and overwrites existing DB with results.
     * @param {string} formId 
     * @param {*} [formData] 
     */
    async syncForm(formId, formData = null) {
        return await syncForm(this.caches.formsCache, formId, formData);
    }

    async getForm(formId) {
        if (this.isOffline) {
            return this.caches.formsCache.get(formId);
        } else {
            const res = await get_form(formId);
            const data = await res.json();
            return data?.form ?? {};
        }
    }

    /**
     * @param {PostEmbeddedForm} embeddedForm 
     */
    async loadEmbeddedSubmission(embeddedForm) {
        const submission = await this.getSubmission(embeddedForm.submission_uuid);
        if (objectIsEmpty(submission)) {
            throw new Error("Could not load embedded submission.");
        }

        submission.form.fields = embeddedForm.form_fields;
        return submission;
    }
}