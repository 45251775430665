import React, {Component, useEffect, useRef, useState} from 'react';

import Skeleton from 'react-loading-skeleton';
import {Popover, Checkbox, Input} from 'antd';
import VirtualList from 'rc-virtual-list';

import {get_assign_users, get_list_users, get_schedule_subscribers} from '../../api/zero-api.js';

import InfoIconComponent from '../InfoIconComponent.js';

import {
    safeProfilePic, safe_get, isViewer, arrayIntersect
} from '../../other/Helper.js';
import NotificationAlert from "../../other/NotificationAlert";


function sortUsers(users, selectedUserIds) {
    return [...users].sort((a, b) => {
        const userASelected = selectedUserIds.includes(safe_get(a, 'uuid', ''));
        const userBSelected = selectedUserIds.includes(safe_get(b, 'uuid', ''));
        const aSort = `${userASelected ? '0' : '1'} ${safe_get(a, "first_name", "").toLowerCase()} ${safe_get(a, "last_name", "").toLowerCase()}`;
        const bSort = `${userBSelected ? '0' : '1'} ${safe_get(b, "first_name", "").toLowerCase()} ${safe_get(b, "last_name", "").toLowerCase()}`;
        return aSort > bSort ? 1 : -1;
    });
}

function UserItem({user}) {
    return (
        <>
            {
                safeProfilePic(user, "img-circle img-sm bulletin", "bulletin", {
                    marginRight: "5px",
                    display: "inline-block"
                })
            }
            <p style={{
                width: "180px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "inline-block",
                verticalAlign: "middle",
                marginBottom: "0px"
            }}>{user.name ? user.name : safe_get(user, "first_name", "")} {safe_get(user, "last_name", "")}</p>
        </>
    )
}

function UserList({users, onUserSelect, selectedUserIds, disabled, containerHeight}) {
    const userStep = 10;
    const userCount = useRef(userStep);
    const [usersToShow, setUsersToShow] = useState(users.slice(0, userCount.current));

    useEffect(() => {
        userCount.current = userStep;
        setUsersToShow(users.slice(0, userCount.current));
    }, [users]);

    const onScroll = (e) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === containerHeight) {
            userCount.current += userStep;
            setUsersToShow(users.slice(0, userCount.current));
        }
    }

    return (
        <VirtualList data={usersToShow} itemKey={'uuid'} height={containerHeight} onScroll={onScroll}>
            {user => (
                <div className="form-check mar-btm-5">
                    <Checkbox
                        onChange={onUserSelect}
                        checked={selectedUserIds.includes(user.uuid)}
                        value={user.uuid}
                        disabled={disabled}
                    >
                        <UserItem user={user} />
                    </Checkbox>
                </div>
            )}
        </VirtualList>
    )
}


class RespondersPopover extends Component {
    constructor(props, context) {
        super(props, context);

        const selectedUserIds = [...this.props.selected_responders].map(user => user.uuid || user.user_uuid);

        this.state = {
            loading_responders: false,
            all_available_responders: safe_get(this.props, "available_responders", []),
            filtered_available_responders: sortUsers(safe_get(this.props, "available_responders", []), selectedUserIds),
            selected_responders: this.props.selected_responders,
            selected_responders_uuids: selectedUserIds,
            assigned_to_team: safe_get(this.props, "schedule.assigned_to_team", false)
        };

        this.showSelectNoneOption = this.props.showSelectNoneOption ?? true;
    }

    componentDidUpdate(prevProps) {
        if ((this.props.selected_responders.length !== prevProps.selected_responders.length)) {
            const selectedUserIds = [...this.props.selected_responders].map(user => user.uuid || user.user_uuid);
            this.setState({
                searchValue: "",
                selected_responders: this.props.selected_responders,
                selected_responders_uuids: selectedUserIds,
                filtered_available_responders: sortUsers(safe_get(this.props, "available_responders", []), selectedUserIds),
            })
        }

        if (this.props.available_responders !== prevProps.available_responders) {
            this.setState({
                all_available_responders: safe_get(this.props, "available_responders", []),
                filtered_available_responders: sortUsers(safe_get(this.props, "available_responders", []), this.state.selected_responders_uuids),
                searchValue: '',
            });
        }
    }

    componentDidMount() {
    }

    async loadResponders() {
        try {
            this.setState({loading_responders: true});
            const schedule_uuid = safe_get(this.props, 'schedule.scheduler_uuid', null);
            const response = await get_schedule_subscribers(schedule_uuid)
            const data = await response.json();
            const subscribers = safe_get(data, 'subscribers', [])

            this.setState({
                selected_responders: subscribers,
                selected_responders_uuids: subscribers.map(user => user.uuid || user.user_uuid),
                loading_responders: false
            });
        } catch (error) {
            console.error('Unable to load subscribers:', error);
            NotificationAlert('error', '', 'Unable to load list of responders.');
            this.setState({
                loading_responders: false,
            })
        }
    }

    handleVisibleChange = popoverVisible => {
        this.setState({popoverVisible});

        if (popoverVisible && !this.props.available_responders) {
            this.getResponderOptions();
        }

        if (popoverVisible && this.props.schedule) {
            const schedule = safe_get(this.props, 'schedule', {});
            const subscribers_count = safe_get(schedule, 'subscribers_count', 0);

            if (subscribers_count !== this.state.selected_responders.length) {
                this.loadResponders();
            }
        }

        if (!popoverVisible) {
            const selectedUserIds = [...this.props.selected_responders].map(user => user.uuid || user.user_uuid);
            this.setState({
                filtered_available_responders: sortUsers(this.state.all_available_responders, selectedUserIds),
                searchValue: "",
                selected_responders_uuids: selectedUserIds
            });
        }

    };

    getResponderOptions = () => {
        var self = this;

        this.setState({loading_responders: true});

        if (this.props.post_uuid) {
            let body = JSON.stringify({post_uuid: this.props.post_uuid})

            get_assign_users(body).then(function (success) {
                success.json().then(success => {
                    var users = safe_get(success, "users", []).sort(function (a, b) {
                        return safe_get(a, "first_name", "").toLowerCase() > safe_get(b, "first_name", "").toLowerCase() ? 1 : -1;
                    });

                    self.setState({
                        all_available_responders: users,
                        filtered_available_responders: sortUsers(users, self.state.selected_responders_uuids),
                        loading_responders: false
                    });

                });
            }, function (error) {

            });
        } else if (this.props.schedule) {
            let schedule_teams = this.props.schedule.teams.map(team => team.team_uuid);

            get_list_users("?status=active").then(function (success) {
                success.json().then(success => {
                    var users = safe_get(success, "users", []);

                    users = users.filter(function (user) {
                        let user_teams = user.teams.map(team => team.uuid)
                        let intersect = arrayIntersect(schedule_teams, user_teams)
                        return user.status === "active" && !isViewer(user) && intersect.length > 0

                    })

                    self.setState({
                        all_available_responders: users,
                        filtered_available_responders: sortUsers(users, self.state.selected_responders_uuids),
                        loading_responders: false
                    });
                });
            }, function (error) {
                console.log(error)
            });
        }


    }

    searchUsers = (e) => {
        let searchValue = safe_get(e, "target.value", "").toLowerCase();

        if (searchValue !== "") {
            var filterList = [...this.state.all_available_responders].filter(function (user) {
                let full_name = user.name || `${user.first_name} ${user.last_name}`
                return full_name.toLowerCase().includes(searchValue)
            });
            this.setState({
                filtered_available_responders: sortUsers(filterList, this.state.selected_responders_uuids),
                searchValue
            });
        } else {
            this.setState({
                filtered_available_responders: sortUsers(this.state.all_available_responders, this.state.selected_responders_uuids),
                searchValue
            });
        }
    }

    handleUserSelect = (e) => {
        let user_uuid = e.target.value
        let selected_responders_uuids = [...this.state.selected_responders_uuids];

        let index = selected_responders_uuids.indexOf(user_uuid)
        if (index >= 0) {
            selected_responders_uuids.splice(index, 1);
        } else {
            selected_responders_uuids.push(user_uuid);
        }

        this.setState({
            selected_responders_uuids: selected_responders_uuids,
            assigned_to_team: selected_responders_uuids.length === this.state.all_available_responders.length
        });
    }

    selectAll = () => {
        let assigned_to_team = !this.state.assigned_to_team

        let selected_responders_uuids = [...this.state.selected_responders_uuids];

        if (assigned_to_team) {
            selected_responders_uuids = [...this.state.all_available_responders].map(user => user.uuid || user.user_uuid);
        } else {
            selected_responders_uuids = [];
        }

        this.setState({assigned_to_team: assigned_to_team, selected_responders_uuids: selected_responders_uuids});
    }

    save = () => {
        if (this.props.schedule) {
            this.props.updateResponders(this.state.selected_responders_uuids, this.state.assigned_to_team)
        } else {
            this.props.updateResponders(this.state.selected_responders_uuids)
        }
        this.setState({popoverVisible: false});
    }

    renderContent = () => {
        return (
            <div style={{width: "270px"}}>
                <div className="mar-btm-10">
                    <h3 className="bulletin-post-headers" style={{margin: "0px"}}>
                        {
                            this.props.title &&
                            <>{this.props.title}</>
                        }
                        {
                            !this.props.title &&
                            <>Assignees
                                <InfoIconComponent
                                    position={"bottom"}
                                    width={"170px"}
                                    text={
                                        <span>Assignees will receive a notification that they've been assigned.</span>}
                                />
                            </>
                        }
                    </h3>
                    <button className="btn btn-primary" style={{float: "right", padding: "2px 5px"}}
                            onClick={this.save}>Save
                    </button>
                </div>

                <Input
                    placeholder="Search..."
                    onChange={this.searchUsers}
                    value={this.state.searchValue}
                    style={{width: "100%"}}
                />

                <div>
                {this.showSelectNoneOption && (
                    <>
                        <button
                            className="ButtonLink blue-link"
                            style={{marginTop: '8px'}}
                            onClick={() => this.setState({selected_responders_uuids: this.state.all_available_responders.map(user => user.uuid)})}
                        >Select All</button>
                    <>&bull;</>  
                    <button
                        className="ButtonLink blue-link"
                        style={{marginTop: '8px'}}
                        onClick={() => this.setState({selected_responders_uuids: []})}
                    >Select None</button>
                    </>

                )}
                </div>
                <div className="subscribers-checkbox" style={{marginTop: "8px", minHeight: "165px"}}>
                    {
                        this.state.loading_responders &&
                        [...Array(5)].map((e, index) => (
                            <div key={index} className="form-check mar-btm-5">
                                <Skeleton width={150}/>
                            </div>
                        ))
                    }
                    {
                        !this.state.loading_responders &&
                        this.props.schedule &&
                        this.state.all_available_responders.length > 0 &&
                        <div className="form-check mar-btm-5">
                            <Checkbox
                                onChange={this.selectAll}
                                checked={this.state.assigned_to_team}
                            >
                                <span
                                    style={{fontWeight: "500"}}>All Team Members ({this.state.all_available_responders.length})</span>
                            </Checkbox>
                        </div>
                    }
                    {
                        !this.state.loading_responders &&
                        this.state.filtered_available_responders.length > 0 &&
                        <UserList
                            users={this.state.filtered_available_responders}
                            onUserSelect={this.handleUserSelect}
                            selectedUserIds={this.state.selected_responders_uuids}
                            disabled={this.props.schedule && this.state.assigned_to_team}
                            containerHeight={165}
                        />
                    }
                    {
                        !this.state.loading_responders &&
                        this.state.filtered_available_responders.length === 0 &&
                        <span className="zero-dark-grey">No members</span>
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <Popover 
                trigger="click"
                placement="right"
                content={this.renderContent}
                style={{padding: "0", width: "270px"}}
                onOpenChange={this.handleVisibleChange}
                open={this.state.popoverVisible}
                destroyTooltipOnHide={true}
            >
                { this.props.wrapWithButton ? (
                    <button className="ButtonLink">{this.props.children}</button>
                ) : (
                    <span style={{cursor: 'pointer'}}>{this.props.children}</span>
                )
                }
            </Popover>
        )
    }

}

export default RespondersPopover