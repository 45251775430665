// @ts-check
import {
    CheckCircleTwoTone,
    DeleteOutlined,
    EditOutlined
} from "@ant-design/icons";
import { Dropdown, Tag } from "antd";
import UserAvatar from "components/Shared/UserAvatar";
import UserPopoverCard from "components/Shared/UserPopoverCard";
import { useCurrentUser } from "hooks/reduxHooks";
import { isArray } from "lodash-es";
import { useMemo } from 'react';
import { IoIosMore as MoreIcon } from 'react-icons/io';
import TaskAssignmentAvatarList from "../TaskAssignmentAvatarList";
import { useTaskDataGridContext } from './TaskDataGrid.context';
import { Link } from "react-router-dom";
import useOrganizationId from "hooks/useOrganizationId";
import { FormType } from "other/Constants";

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function TaskActionsCell({data: task}) {
    const user = useCurrentUser();
    const { setActiveModal } = useTaskDataGridContext();

    if (!task) {
        return null;
    }

    const handleMenuItemClicked = ({key}) => {
        setActiveModal({
            key,
            task,
        });
    }

    const tableMenu = useMemo(() => {
        return {
            onClick: handleMenuItemClicked,
            items: [
                {
                    key: "detail",
                    icon: <EditOutlined/>,
                    label: "Edit"
                },
                {
                    key: "delete",
                    className: "zero-delete-red",
                    icon: <DeleteOutlined/>,
                    label: "Delete"
                }
            ],
        }
    }, [task, user]);

    return (
        <div className="more pull-right" style={{display: "inline-block", color: "grey", lineHeight: 0}}>
            {/* @ts-ignore */}
            <Dropdown menu={tableMenu} trigger={['click']} placement="bottomRight">
                <button className="ButtonLink ant-dropdown-link">
                    <MoreIcon style={{height: '20px', width: '20px'}}/>
                </button>
            </Dropdown>
        </div>
    );
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function TaskTitleCell({data: task}) {
    const {setActiveModal} = useTaskDataGridContext();

    if (!task) {
        return null;
    }

    return (
        <span className="blue-link" onClick={() => setActiveModal({key: 'detail', task})}>{task.title}</span>
    )

}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function StatusCell(props) {
    if (!props.data) {
        return null;
    }
    
    if (props.value === "closed") {
        return (
            <Tag color="green" style={{ borderColor: "#52c41a", fontSize: "11px" }}>
                {/* @ts-ignore */}
                <CheckCircleTwoTone twoToneColor={"#52c41a"} /> Closed
            </Tag>
        );
    }

    return <Tag style={{ color: "#505050", fontSize: "11px" }}>Open</Tag>;
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function TaskAssigneesCell(props) {
    const { value: assignedUsers, data: task, setValue } = props;

    if (!isArray(assignedUsers)) {
        return null;
    }

    const updateAssignedUsers = (task) => {
        setValue(task.assigned_users);
    }

    return (
        <TaskAssignmentAvatarList task={task} onMutation={updateAssignedUsers} />
    );
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function UserAvatarCell(props) {
    const { value: user } = props;

    if (!user) {
        return null;
    }

    return (
        <UserPopoverCard
            user={user}
        >
            <button className="ButtonLink">
                <UserAvatar user={user} imgClass="img-sm img-circle bulletin" initialsClass="bulletin" />
            </button>
        </UserPopoverCard>
    );
}

/**
 * @param {AgGridReactLib.CustomCellRendererProps} props
 */
export function RelatedToCell(props) {
    const orgId = useOrganizationId();

    /** @type {Task} */
    const task = props.data;

    if (!task?.linked_submission?.submission_uuid) {
        return null;
    }

    const submission = task.linked_submission;
    const formTypeName = FormType.mapToName(submission.form_type);

    let link = `/${orgId}/home/team/my_teams/forms/submission_view/${submission.submission_uuid}`;
    if (submission.incident_uuid) {
        link = `/${orgId}/home/incidents/location/all_locations/edit_incident/${submission.incident_uuid}/1`
    }

    return (
        <Link className="blue-link" to={link}>{formTypeName} Submission</Link>
    )
}